import React, { useState } from 'react';
import { Box, Button, Typography, Menu, MenuItem, CircularProgress, Divider } from '@mui/material';
import { ExpandMore, Folder, KeyboardArrowDown } from '@mui/icons-material';
import useShowError from 'modules/errors';
import { getDateStringWithoutTimezone } from 'services/logic/dateTime';

import api from 'services/api/autogenerated';

import { useSelector } from 'react-redux';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';

export interface PdfTypeSelectionDropdownProps {
  disabled?: boolean;
  project: any; // should be Project
  filters: {
    filterDateRange: any[];
  };
  goToPdfsTabCallback?: () => any;
  useNewDownloads: boolean;
}

const PdfTypeSelectionDropdown = ({
  disabled,
  project,
  filters,
  goToPdfsTabCallback,
  useNewDownloads,
}: PdfTypeSelectionDropdownProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const showError = useShowError();
  const [isLoadingDownloadManifests, setIsLoadingDownloadManifests] = useState(false);
  const [isLoadingDownloadManifestsPerDay, setIsLoadingDownloadManifestsPerDay] = useState(false);
  const authUser = useSelector(selectAuthUser);

  const { filterDateRange } = filters;

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const onManifestsClickCallback = async () => {
    setIsLoadingDownloadManifests(true);
    try {
      await handleManifestDownloadRequest(false);
      closeMenu();
      if (goToPdfsTabCallback) goToPdfsTabCallback();
    } catch (err) {
      console.error(err);
      showError({ title: 'Error download manifests', duration: 10000 });
    }
    setIsLoadingDownloadManifests(false);
  };

  const onManifestsPerDayClickCallback = async () => {
    setIsLoadingDownloadManifestsPerDay(true);
    try {
      await handleManifestDownloadRequest(true);
      closeMenu();
      if (goToPdfsTabCallback) goToPdfsTabCallback();
    } catch (err) {
      console.error(err);
      showError({ title: 'Error download manifests per day', duration: 10000 });
    }
    setIsLoadingDownloadManifestsPerDay(false);
  };

  const handleManifestDownloadRequest = async (perDay: boolean) => {
    const body = {
      projectId: project.id,
      projectProfileNr: project.profileNr,
      projectTimeZone: project.timeZone,
      filters: { ...filters },
      startDate: getDateStringWithoutTimezone(filterDateRange[0]),
      endDate: getDateStringWithoutTimezone(filterDateRange[1]),
      perDay,
    };

    if (useNewDownloads) {
      await api.downloads.multipleManifestDownloadRequest(body);
    } else {
      await api.pubSub.downloadManifests(body);
    }
  };

  return (
    <>
      <Button style={{ height: '32px' }}
        variant="outlined"
        fullWidth
        onClick={e => setAnchorEl(e.currentTarget)}
        title="Select PDF type"
        aria-controls="pdfstypeselection"
        aria-haspopup="true"
        disabled={disabled || !filterDateRange || !filterDateRange.length}
      >
        <Box display="flex" alignItems="center">
          <ExpandMore fontSize="medium" />
          <Box display="flex" alignItems="center">
            {(isLoadingDownloadManifestsPerDay || isLoadingDownloadManifests) && (
              <CircularProgress color="inherit" size={30} />
            )}
            PDFs
          </Box>
        </Box>
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        id="pdfstypeselection"
      >
        <Box paddingLeft={2} paddingRight={2}>
          <Box border="1px solid black">
            <MenuItem>
              <Box
                flex={1}
                display="flex"
                flexDirection="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Typography variant="h6">PDFs</Typography>
                <KeyboardArrowDown fontSize="large" />
              </Box>
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={onManifestsPerDayClickCallback}
              disabled={isLoadingDownloadManifestsPerDay}
            >
              <Box
                flex={1}
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box marginLeft={1} marginRight={4}>
                  In folders by date
                </Box>
                {isLoadingDownloadManifestsPerDay ? (
                  <CircularProgress color="inherit" size={30} />
                ) : (
                  <img
                    src="/static/images/icons/folders.svg"
                    width={30}
                    height={30}
                    alt="Folders icon"
                  />
                )}
              </Box>
            </MenuItem>
            <MenuItem onClick={onManifestsClickCallback} disabled={isLoadingDownloadManifests}>
              <Box
                flex={1}
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <Box marginLeft={1}>In single folder</Box>
                {isLoadingDownloadManifests ? (
                  <CircularProgress color="inherit" size={30} />
                ) : (
                  <Folder fontSize="medium" />
                )}
              </Box>
            </MenuItem>
          </Box>
        </Box>
      </Menu>
    </>
  );
};

export default PdfTypeSelectionDropdown;
