import React, { useCallback, useState, useEffect, useMemo } from 'react';
import './NotesAndCIs.scss';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  CircularProgress,
  Box,
  IconButton,
  Tab,
  Tabs,
  List,
} from '@mui/material';
import { useSelector } from 'react-redux';
import Note from './Note';
import ManifestsDetailsView from 'sharedComponents/ManifestsDetailsView';
import ManifestsReceiptDetailsView from 'sharedComponents/ManifestsReceiptDetailsView';
import CustomInputsRenderer from 'sharedComponents/CustomInputsRenderer';
import ClosePictogram from '@mui/icons-material/Close';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import Executer from 'services/firebase/Executer';
import Query from 'services/firebase/Query';
import useShowError from 'modules/errors';
import Changelog from './Changelog';
import NotesAddNewOrEdit from './NotesAddNewOrEdit';
import { UserTypes } from 'lg-helpers/dist/constants/user/UserTypes';
import QueryBase from 'lg-helpers/dist/firestore/query/QueryBase';

const executer = new Executer();
const query = new Query();

const Notes = ({ manifest, isNotesOpen, handleCloseNotes }) => {
  const showError = useShowError();
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [isLoadingNotes, setIsLoadingNotes] = useState(false);
  const authUser = useSelector(selectAuthUser);
  const isSuperAdmin = useMemo(() => authUser?.type === 'Super Admin', [authUser]);
  const [notes, setNotes] = useState([]);
  const [changelog, setChangelog] = useState([]);
  const [tabValue, setTabValue] = useState('notes');
  const checkEditReceiptWeight = useCallback(async () => {
    if (manifest && manifest?.receipt && manifest?.receipt?.manuallyEntered) {
      if (authUser?.type === UserTypes.admin.super) return true;
      try {
        const proj = await executer.getSingleDocument(
          query.base.getById(QueryBase.PROJECTS_COLLECTION(), manifest.project.id)
        );
        return (
          authUser?.type === UserTypes.scale.admin &&
          authUser?.companiesIds?.includes(proj?.scaleCompany?.id)
        );
      } catch (error) {
        return false;
      }
    } else {
      return false;
    }
  }, [manifest, authUser]);

  const [canEditReceiptWeight, setCanEditReceiptWeight] = useState(false);

  useEffect(() => {
    let active = true;
    load();
    return () => {
      active = false;
    };

    async function load() {
      const res = await checkEditReceiptWeight();
      if (!active) {
        return;
      }
      setCanEditReceiptWeight(res);
    }
  }, [manifest, authUser, checkEditReceiptWeight]);

  useEffect(() => {
    if (!manifest) {
      return;
    }

    setIsReadOnly(!isSuperAdmin && manifest.signatureScale && manifest.signatureScale.isSigned);

    const unsubscribe = executer.watchMultipleDocuments(
      query.manifests.getManifestNotes(manifest.id),
      notesResponse => {
        setNotes(notesResponse);
        setIsLoadingNotes(false);
      },
      error => {
        console.error('Error', error);
        showError({
          title: 'Error while fetching the projects',
          duration: 10000,
        });
        setIsLoadingNotes(false);
      }
    );

    const changelogUnsubscribe = executer.watchMultipleDocuments(
      query.manifests.getManifestChangelog(manifest.id),
      changelogResponse => {
        setChangelog(changelogResponse);
      },
      error => {
        console.error('Error', error);
        showError({
          title: 'Error while fetching the changelog',
          duration: 10000,
        });
        setIsLoadingNotes(false);
      }
    );

    return () => {
      unsubscribe();
      changelogUnsubscribe();
    };
    // eslint-disable-next-line
  }, [manifest]);

  return (
    <Dialog
      open={isNotesOpen}
      onClose={handleCloseNotes}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth={true}
      className="Notes Notes__dialog"
    >
      <DialogTitle id="scroll-dialog-title">
        <Grid container>
          <Grid item xs={9}>
            <Tabs
              value={tabValue}
              indicatorColor="primary"
              textColor="primary"
              onChange={(event, newValue) => {
                setTabValue(newValue);
              }}
            >
              <Tab label={`Notes (${manifest?.notesCount})`} value="notes" />
              <Tab label="Changelog" value="changelog" />
            </Tabs>
          </Grid>

          <Grid item xs={3}>
            <Box display="flex" justifyContent="flex-end">
              <IconButton edge="end" aria-label="delete" onClick={handleCloseNotes}>
                <ClosePictogram />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers={true}>
        {tabValue === 'notes' && (
          <Grid container>
            <Grid container spacing={1}>
              {manifest && authUser?.allowedUserTypes?.includes('Super Admin') && (
                <Grid item xs={12}>
                  <ManifestsDetailsView manifest={manifest} />
                </Grid>
              )}

              {canEditReceiptWeight && (
                <Grid item xs={12}>
                  <ManifestsReceiptDetailsView title="Receipt Details" manifest={manifest} />
                </Grid>
              )}

              {manifest?.project?.customInputsDefinitions && (
                <Grid item xs={12}>
                  <CustomInputsRenderer
                    section="Notes"
                    manifest={manifest}
                    isReadOnly={isReadOnly}
                  />
                </Grid>
              )}

              <NotesAddNewOrEdit manifest={manifest} isNotesOpen={isNotesOpen} />
            </Grid>
            {isLoadingNotes ? (
              <Grid item xs={12}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <CircularProgress color="inherit" />
                </Box>
              </Grid>
            ) : (
              <List className="Notes__list" sx={{ width: '100%', bgcolor: 'background.paper' }}>
                {notes.map(note => (
                  <Note key={note.id} note={note} manifest={manifest} />
                ))}
              </List>
            )}
          </Grid>
        )}

        {tabValue === 'changelog' && (
          <Changelog changelogs={changelog} timezone={manifest.project.timeZone} />
        )}
      </DialogContent>
    </Dialog>
  );
};

Notes.propTypes = {
  isNotesOpen: PropTypes.bool,
  handleCloseNotes: PropTypes.func,
  manifest: PropTypes.object,
};

export default Notes;
