import React, { useState, useEffect, useCallback } from 'react';
import { Grid, CircularProgress, Container } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import PrintIcon from '@mui/icons-material/Print';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import EmailIcon from '@mui/icons-material/Email';
import CommentIcon from '@mui/icons-material/Comment';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import InputIcon from '@mui/icons-material/Article';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import Page from 'sharedComponents/Page';
import Notes from 'sharedComponents/Notes.js';
import CustomInputs from 'sharedComponents/CustomInputs.js';
import ManifestDisplayWithTemplateDataGathering from 'sharedComponents/ManifestDisplayWithTemplateDataGathering';
import {
  getManifestVisibleCustomInputsDefinitionsCanEdit,
  getManifestVisibleCustomInputsDefinitionsCanOnlyRead,
} from 'utils/manifestUtil';
import useWatchManifestIfSignedByScaleReleaseDriver from 'services/hooks/useWatchManifestIfSignedByScaleReleaseDriver';
import { emailManifest } from 'api/manifest';
import ButtonWithLoading from 'sharedComponents/ButtonWithLoading';
import useShowError from 'modules/errors';
import EmailDialog from './EmailDialog';
import SelectBatchedManifests from './SelectBatchedManifests';
import useDownloadManifestOpenNewPage from 'sharedComponents/useDownloadManifestOpenNewPage';

const ManifestView = () => {
  const showError = useShowError();
  let { id } = useParams();
  const authUser = useSelector(selectAuthUser);
  useWatchManifestIfSignedByScaleReleaseDriver();
  const navigate = useNavigate();
  const [isNotesOpen, setIsNotesOpen] = useState(false);
  const [isCIsOpen, setIsCIsOpen] = useState(false);
  const [selectedManifest, setSelectedManifest] = useState(null);
  const [customInputsCanEditCount, setCustomInputsCanEditCount] = useState(0);
  const [customInputsReadOnlyCount, setCustomInputsReadOnlyCount] = useState(0);
  const [isEmailDialog, setIsEmailDialog] = useState(false);
  const { openTabbedManifest: handleClickDownload } =
    useDownloadManifestOpenNewPage(selectedManifest);
  const [sm, setSm] = useState(2);

  useEffect(() => {
    if (!authUser || !selectedManifest) {
      return;
    }else{
      if(authUser.actingAsType === 'Scale Admin' || authUser.actingAsType === 'Scale Attendant'){
        setSm(1);
      }
    }

    getManifestVisibleCustomInputsDefinitionsCanEdit(selectedManifest, authUser.actingAsType).then(
      canEdit => {
        if (canEdit?.length) {
          setCustomInputsCanEditCount(canEdit.length);
        }
      }
    );
    getManifestVisibleCustomInputsDefinitionsCanOnlyRead(
      selectedManifest,
      authUser.actingAsType
    ).then(readOnly => {
      if (readOnly?.length) {
        setCustomInputsReadOnlyCount(readOnly.length);
      }
    });
  }, [authUser, selectedManifest]);

  const handleClickPrint = useCallback(async () => {
    let w;
    try {
      w = window.open('', '_blank');
      const html = document
        .querySelector('.lg-iframe')
        .contentWindow.document.body.parentElement.outerHTML.replace(
          '<title>Manifest</title>',
          `<title>${selectedManifest.number}</title>`
        )
        .replace(
          '</body>',
          `<script> document.addEventListener('DOMContentLoaded', function() { window.print(); }); </script></body>`
        );
      w.document.write(html);
      w.focus();
      w.document.close();
    } catch (err) {
      console.error(err);
      if (w) w.close();
      showError({
        title: 'Print manifest failed',
        text: err.message,
        duration: 10000,
      });
    }
  }, [selectedManifest, showError]);

  if (!id) {
    return null;
  }

  return (
    <Page title="Manifest">
      <Container>
        <div style={{ paddingLeft: 20, paddingRight: 20 }}>
          <Grid container spacing={1} marginTop={5}>
            {selectedManifest && (
              <>
                <Grid item sm={sm} xs={6}>
                  <ButtonWithLoading
                    isLarge
                    isFullWidth
                    Pictogram={NavigateBeforeIcon}
                    onClickCallback={() => navigate(-1)}
                  />
                </Grid>
                <Grid item sm={2} xs={6}>
                  <ButtonWithLoading
                    isLarge
                    isFullWidth
                    Pictogram={CommentIcon}
                    onClickCallback={() => setIsNotesOpen(true)}
                    color="secondary"
                    text={`${selectedManifest.notesCount} Notes ${
                      customInputsCanEditCount + customInputsReadOnlyCount
                        ? `, ${customInputsCanEditCount + customInputsReadOnlyCount} C.I.`
                        : ''
                    }`}
                  />
                </Grid>
                  {(authUser.actingAsType === 'Scale Admin' || authUser.actingAsType === 'Scale Attendant') &&
                  <Grid item sm={2} xs={6}>
                    <ButtonWithLoading
                      isLarge
                      isFullWidth
                      Pictogram={InputIcon}
                      onClickCallback={() => setIsCIsOpen(true)}
                      color="secondary"
                      text="Inputs"
                    />
                  </Grid>}
                <Grid item sm={sm} xs={6}>
                  <ButtonWithLoading
                    isLarge
                    isFullWidth
                    Pictogram={LocationOnIcon}
                    onClickCallback={() => navigate(`/manifests/${id}/map`)}
                  />
                </Grid>
                <Grid item sm={2} xs={6}>
                  <ButtonWithLoading
                    isLarge
                    isFullWidth
                    disabled={!selectedManifest}
                    Pictogram={CloudDownloadIcon}
                    onClickCallback={handleClickDownload}
                    text="Download"
                  />
                </Grid>
                <Grid item sm={2} xs={6}>
                  <ButtonWithLoading
                    isLarge
                    isFullWidth
                    disabled={!selectedManifest}
                    Pictogram={PrintIcon}
                    onClickCallback={handleClickPrint}
                    text="Print"
                  />
                </Grid>
                <Grid item sm={2} xs={6}>
                  <ButtonWithLoading
                    isLarge
                    isFullWidth
                    disabled={!selectedManifest}
                    Pictogram={EmailIcon}
                    onClickCallback={() => setIsEmailDialog(true)}
                    text="Email"
                  />
                </Grid>
              </>
            )}
            {selectedManifest?.batchId && (
              <Grid item xs={12}>
                <SelectBatchedManifests batchId={selectedManifest.batchId} />
              </Grid>
            )}
            <Grid item xs={12}>
              {id ? (
                <ManifestDisplayWithTemplateDataGathering
                  manifestId={id}
                  onManifestUpdateReceivedCallback={setSelectedManifest}
                />
              ) : (
                <CircularProgress color="inherit" />
              )}
            </Grid>
          </Grid>
        </div>
      </Container>
      {selectedManifest && (
        <Notes
          manifest={selectedManifest}
          isNotesOpen={isNotesOpen}
          handleCloseNotes={() => setIsNotesOpen(false)}
        />
      )}
      {selectedManifest && (
        <CustomInputs
          manifest={selectedManifest}
          isCIsOpen={isCIsOpen}
          handleCloseCIs={() => setIsCIsOpen(false)}
        />
      )}
      <EmailDialog
        open={isEmailDialog}
        handleClose={() => setIsEmailDialog(false)}
        handleSubmit={async data => await emailManifest(id, data.emails, data.isCopyToMe)}
      />
    </Page>
  );
};

export default ManifestView;
