import React, { useEffect, useState } from 'react';
import './NotesAndCIs.scss';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Box,
  IconButton,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem
} from '@mui/material';
import ClosePictogram from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import Query from 'services/firebase/Query';
import Command from 'services/firebase/Command';
import useShowError from 'modules/errors';
import { forEach } from 'lodash';

const CustomInputs = ({ manifest, isCIsOpen, handleCloseCIs }) => {
  const authUser = useSelector(selectAuthUser);
  const [customInputs, setCustomInputs] = useState(manifest?.customInputs || {});
  const [changelog, setChangelog] = useState([]);
  const query = new Query();
  const command = new Command();
  const showError = useShowError();

  useEffect(() => {
    if (manifest?.customInputs) {
      setCustomInputs(manifest.customInputs);
    }else{
      showError({
        title: 'There are no custom inputs for this manifest.',
        duration: 3000,
      });
      return;
    }
    setChangelog(query.manifests.getManifestChangelog(manifest.id));
  }, [manifest]);

  const handleInputChange = (key, value) => {
    const updatedInputs = { ...customInputs };
    updatedInputs[key].value = value;
    setCustomInputs(updatedInputs);
  };

  const renderValueField = (inputKey, input) => {
    const def = manifest?.project?.customInputsDefinitions.find(def => def.propertyPathInManifestCustomInputs === inputKey);
    if (!def || def.type === 'Signature') {
      return null;
    }
    console.log(def.propertyPathInManifestCustomInputs);
    console.log(inputKey);
    if ((def.type === 'List' || def.type === 'Checkbox') && Array.isArray(def.items)) {
      return (
        <Box key={inputKey} mb={2}>
          <Typography variant="h6" gutterBottom>
            {inputKey}
          </Typography>
          <Select
            label="Value"
            value={input['value']}
            onChange={(e) => handleInputChange(inputKey, e.target.value)}
            fullWidth
            margin="dense"
          >
            {def.items.map((item, index) => (
              <MenuItem key={index} value={item.value}>
                {item.value} {/* Display the value */}
              </MenuItem>
            ))}
          </Select>
        </Box>
      );
    }
    if (input.hasOwnProperty('value') && typeof input['value'] === 'string') {
      return (
        <Box key={inputKey} mb={2}>
          <Typography variant="h6" gutterBottom>
            {inputKey}
          </Typography>
          <TextField
            label="Value"
            value={input['value']}
            onChange={(e) => handleInputChange(inputKey, e.target.value)}
            fullWidth
            margin="dense"
          />
        </Box>
      );
    }
    return null;
  };

  const handleSave = async (updatedInputs) => {
    const newChangelogObject = {
      action: "UPDATED_CUSTOM_INPUTS",
      isNative: false,
      manifestId: manifest.id,
      manifestNumber: manifest.manifestNumber,
      occuredAt: new Date(),
      osVersion: 'N/A',
      platform: 'N/A',
      userId: authUser.id,
      userName: authUser.firstName + ' ' + authUser.lastName
    };
    try {
      await command.manifests.addManifestChangeLog(manifest.id, newChangelogObject);
    } catch (error) {
      console.log('error: ', error);
    }
    try {
      await command.manifests.updateManifestCustomInputs(manifest.id, updatedInputs);
      handleCloseCIs();
    } catch (error) {
      showError({
        title: 'Error during save: ' + error,
        duration: 10000,
      });
    }
    setKeyOrder(Object.keys(manifest.customInputs));
  };

  return (
    <Dialog
      open={isCIsOpen}
      onClose={handleCloseCIs}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle id="scroll-dialog-title">
        <Grid container justifyContent="space-between" alignItems="center">
          <Box fontSize="1.5rem">Custom Inputs</Box>
          <Box display="flex" gap={1}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleSave(customInputs)}
            sx={{ mr: 2 }}>
              Save
            </Button>
            <IconButton edge="end" aria-label="delete" onClick={handleCloseCIs}>
              <ClosePictogram />
            </IconButton>
          </Box>
        </Grid>
      </DialogTitle>
      <DialogContent dividers={true}>
        <Box
          sx={{
            maxHeight: '400px',
            overflowY: 'auto',
          }}
        >
          <Grid container>
            <Grid container spacing={1}>
              {customInputs && (
                <Grid item xs={12}>
                  {Object.keys(customInputs).map((inputKey) =>
                    renderValueField(inputKey, customInputs[inputKey])
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

CustomInputs.propTypes = {
  isCIsOpen: PropTypes.bool,
  handleCloseCIs: PropTypes.func,
  manifest: PropTypes.object
};

export default CustomInputs;