import React, { HTMLAttributes, useCallback, useContext, useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  TextField,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Switch,
  CircularProgress,
  Box,
  Autocomplete,
  Button,
  FormGroup,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InputMasker from './InputMasker';
import InputGeocodingAddress from 'sharedComponents/InputGeocodingAddress';
import AdministratorPanel from './AdministratorPanel';
import timeZones from 'services/constants/momentJsTimeZones';
import PermissionsWrapper from 'sharedComponents/PermissionsWrapper';
import CrudListIdName from 'sharedComponents/CrudListIdName';
import ErrorMessages from 'services/constants/errorMessages';
import { UserTypes } from 'lg-helpers/dist/constants/user/UserTypes';
import InviteCompanyPopup from 'modules/common/companies/InviteCompanyPopup';
import CustomInputsDefinitionsCrud from './CustomInputsDefinitionsCrud';
import CompaniesApi from 'services/postgres/CompaniesApi';
import { DatePicker } from 'rsuite';
import InputContractorCompany from './InputContractorCompany';
import InputTransporterCompany from './InputTransporterCompany';
import { ICompany } from 'lg-helpers/dist/shared/interfaces/ICompany';
// import { IProjectDetailsEdit } from '../../interfaces/IProjectDetailsEdit';
import { ManifestProjectContacts } from 'sharedComponents/ManifestProjectContacts';
import { ModalContext } from 'sharedComponents/ModalContext';
import './ProjectDetailsEdit.scss';
import { CustomInputsDuplicate } from './CustomInputsDuplicate';
import Query from 'services/firebase/Query';
import Executer from 'services/firebase/Executer';
import { Manifest } from 'services/api/autogenerated-code';
import { usePermissionsContext } from 'services/permissions';

interface Templates {
  [key: string]: any;
}

const query = new Query();
const executer = new Executer();

const { isRequired } = ErrorMessages;

const companiesApi = new CompaniesApi();

export const getProjectScaleCompanyProperty = (companyWithCustomData: any) => ({
  id: companyWithCustomData.id,
  name: companyWithCustomData.name,
  externalApiPropertyNamesForMappingsInProjects:
    companyWithCustomData.externalApiPropertyNamesForMappingsInProjects || [],
  fileNameActionsInCronJob: companyWithCustomData.fileNameActionsInCronJob || null,
  regions: companyWithCustomData.regions || [],
  companyLogo: companyWithCustomData?.companyLogo || '',
});

const ProjectDetailsEdit = ({
  project,
  isProjectDetailsExpanded,
  isAuthUser,
  isLoadingCompanies,
  isLoadingScales,
  wasSubmitted,
  generators,
  viewers,
  canProjectViewAllManifests,
  scaleCompanies,
  scales,
  hasErrorRequired,
  handleChangeCanViewCheckbox,
  handleChange,
  handleAccordionStateChange,
  onSiteAdressChange,
  onCoordinatesChange,
  handleChangeExpiryDate,
  handleChangeInExternalApiMappings,
  handleCustomInputsDefinitionsChange,
  cTransporters,
  brokers,
  contractors,
  templates,
}: any) => {
  const { user: authUser } = usePermissionsContext();
  const [hasManifests, setHasManifests] = useState(false);
  const [manifestTemplates, setManifestTemplates] = useState<Templates[]>(
    templates.manifestTemplates || []
  );
  const [receiptTemplates, setReceiptTemplates] = useState<Templates[]>(
    templates.receiptTemplates || []
  );
  const { handleModal } = useContext(ModalContext);
  const getStateLabel = useCallback(
    (stateObj: any) => (stateObj ? `${stateObj.name} (${stateObj.value})` : ''),
    []
  );

  const onScaleCompanyChange = useCallback(
    async (event: any, value: any) => {
      const companyWithCustomData = await companiesApi.getById(value.id);
      setManifestTemplates(companyWithCustomData?.manifestTemplates || []);
      setReceiptTemplates(companyWithCustomData?.receiptTemplates || []);
      handleChange({
        target: {
          name: 'scaleCompany',
          value: getProjectScaleCompanyProperty(companyWithCustomData),
        },
      });
    },
    [handleChange]
  );

  const handleOpenCompanyContactModal = (
    companyType: 'Generator' | 'Contractor' | 'Transporter',
    allowChange: boolean = true
  ) => {
    handleModal(
      <ManifestProjectContacts
        companyType={companyType}
        project={project}
        handleChange={handleChange}
        allowChange={allowChange}
      />
    );
  };

  useEffect(() => {
    const getManifestsForProject = async () => {
      try {
        const manifestsInProject = (await executer.getMultipleDocuments(
          query.manifests.getManifests(
            '',
            '',
            false,
            project,
            authUser,
            null,
            false,
            false,
            { isNext: null, lastVisibleDoc: null },
            '',
            false,
            true,
            false,
            'all',
            1
          )
        )) as Manifest[];
        setHasManifests(!!manifestsInProject.length as boolean);
      } catch (error) {
        console.error('Error', error);
      }
    };
    getManifestsForProject();
  }, [project]);

  useEffect(() => {
    setManifestTemplates(templates?.manifestTemplates || []);
    setReceiptTemplates(templates?.receiptTemplates || []);
  }, [templates]);

  return (
    <Accordion style={{ marginTop: '4px' }} expanded={isProjectDetailsExpanded} onChange={handleAccordionStateChange}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content">
        <Typography variant="h5">
          Details {project.name} ({project.profileNr})
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container alignItems="center" spacing={3}>
          {project.id && (
            <>
              {(isAuthUser.superAdmin || isAuthUser.customerAdmin) && (
                <>
                  <Grid item xs={12}>
                    <AdministratorPanel project={project} />
                  </Grid>
                  <Grid item xs={12}>
                    <FormGroup className="project-switch-container">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={!!project.isActive}
                            disabled={isAuthUser.readOnly}
                            onChange={handleChange}
                            name="isActive"
                            color="primary"
                          />
                        }
                        label="Is Active"
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            checked={!!project.isArchived}
                            onChange={handleChange}
                            name="isArchived"
                            color="primary"
                          />
                        }
                        label="Archive Project"
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            checked={!!project.canAssignSpecificManifestToDriver}
                            disabled={isAuthUser.readOnly}
                            onChange={handleChange}
                            name="canAssignSpecificManifestToDriver"
                            color="primary"
                          />
                        }
                        label="Can Assign Specific Manifest to Driver"
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            checked={!!project.enabledTurnByTurnNavigation}
                            onChange={handleChange}
                            name="enabledTurnByTurnNavigation"
                            color="primary"
                          />
                        }
                        label="Enable Turn by Turn Navigation"
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            checked={!!project.enableScaleUserReceiptCreation}
                            onChange={handleChange}
                            name="enableScaleUserReceiptCreation"
                            color="primary"
                          />
                        }
                        label="Allow Scale Users to Create Receipt"
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      onChange={handleChange}
                      fullWidth
                      label="File name with actions in cron job"
                      name="fileNameActionsInCronJob"
                      value={project.fileNameActionsInCronJob || ''}
                      variant="outlined"
                    />
                  </Grid>
                </>
              )}
            </>
          )}
          <Grid item sm={6} xs={12}>
            <TextField
              onChange={handleChange}
              required
              fullWidth
              label="Name"
              name="name"
              value={project.name || ''}
              variant="outlined"
              disabled={
                !(
                  isAuthUser.superAdmin ||
                  isAuthUser.customerAdmin ||
                  isAuthUser.generator ||
                  isAuthUser.contractor ||
                  isAuthUser.transporterAdmin
                )
              }
              error={hasErrorRequired(project.name, wasSubmitted)}
              helperText={hasErrorRequired(project.name, wasSubmitted) ? isRequired : ''}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <Autocomplete
              disabled={isAuthUser.readOnly}
              options={timeZones}
              value={project.timeZone || null}
              isOptionEqualToValue={(option, value) => (value && option ? option === value : false)}
              getOptionLabel={option => option}
              onChange={(event, value) =>
                handleChange({
                  target: {
                    name: 'timeZone',
                    value: value,
                  },
                })
              }
              renderOption={(props, option) => (
                <li {...props} key={option || ''}>
                  {option}
                </li>
              )}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                  required
                  label="Time zone"
                  placeholder="Select time zone"
                  error={hasErrorRequired(project.timeZone, wasSubmitted)}
                  helperText={hasErrorRequired(project.timeZone, wasSubmitted) ? isRequired : ''}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: <>{params.InputProps.endAdornment}</>,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              onChange={handleChange}
              required
              fullWidth
              label="Profile Number"
              name="profileNr"
              value={project.profileNr || ''}
              variant="outlined"
              disabled={isAuthUser.readOnly}
              error={hasErrorRequired(project.profileNr, wasSubmitted)}
              helperText={hasErrorRequired(project.profileNr, wasSubmitted) ? isRequired : ''}
            />
          </Grid>
          <PermissionsWrapper canAccess={[UserTypes.admin.super]}>
            <Grid item xs={12}>
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={12}>
                  <InputMasker
                    label="Field Contact"
                    fullWidth
                    name={'fieldContact'}
                    mask="+###########"
                    val={project?.fieldContact || ''}
                    onChange={value =>
                      handleChange({
                        target: {
                          name: 'fieldContact',
                          value,
                        },
                      })
                    }
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Grid>
          </PermissionsWrapper>

          <Grid item xs={12}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={9} md>
                <Autocomplete
                  multiple
                  options={viewers || []}
                  value={project.viewers || []}
                  disabled={!isAuthUser.canAddViewers}
                  isOptionEqualToValue={(option, value) =>
                    value && option ? option.id === value.id : false
                  }
                  getOptionLabel={option => option.name}
                  onChange={(event, value) =>
                    handleChange({
                      target: {
                        name: 'viewers',
                        value: value.map(x => ({
                          id: x.id,
                          name: x.name || null,
                        })),
                      },
                    })
                  }
                  renderOption={(props, option) => (
                    <li {...props} key={option.id || ''}>
                      {option.name}
                    </li>
                  )}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Viewers"
                      placeholder="Select project viewers"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {isLoadingCompanies && <CircularProgress color="inherit" size={20} />}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={3} md="auto">
                <InviteCompanyPopup
                  lockCompanyTypes={['viewer']}
                  inviteUserType={UserTypes.viewer.user}
                  disabled={!isAuthUser.canAddViewers}
                  popupTitle="Invite viewer company"
                  userSectionTitle="Viewer user (will be invited)"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent={'space-between'}>
              <Typography variant="h5">Generator company</Typography>
              <PermissionsWrapper
                canAccess={[
                  UserTypes.admin.super,
                  UserTypes.generator.admin,
                  UserTypes.scale.admin,
                ]}
              >
                <Button
                  color="inherit"
                  variant="outlined"
                  onClick={() =>
                    handleOpenCompanyContactModal(
                      'Generator',
                      ([UserTypes.admin.super, UserTypes.generator.admin] as string[]).includes(
                        authUser.actingAsType
                      )
                    )
                  }
                >
                  Generator Contacts
                </Button>
              </PermissionsWrapper>
            </Box>
          </Grid>
          <Grid item sm={12} xs={12}>
            <Autocomplete
              disabled={isAuthUser.readOnly}
              options={generators || []}
              value={project.generatorCompany || null}
              isOptionEqualToValue={(option, value) =>
                value && option ? option.id === value.id : false
              }
              getOptionLabel={option => option.name}
              onChange={(event, value) =>
                handleChange({
                  target: {
                    name: 'generatorCompany',
                    value: value
                      ? {
                          id: value.id,
                          name: value.name,
                        }
                      : null,
                  },
                })
              }
              renderOption={(props, option) => (
                <li {...props} key={option.id || ''}>
                  {option.name}
                </li>
              )}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                  required
                  label="Generator"
                  placeholder="Select generator"
                  error={hasErrorRequired(project.generatorCompany, wasSubmitted)}
                  helperText={
                    hasErrorRequired(project.generatorCompany, wasSubmitted) ? isRequired : ''
                  }
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {isLoadingCompanies && <CircularProgress color="inherit" size={20} />}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </Grid>

          <InputContractorCompany
            isAuthUser={isAuthUser}
            contractors={contractors}
            isLoadingCompanies={isLoadingCompanies}
            wasSubmitted={wasSubmitted}
            isRequired={isRequired}
            getStateLabel={getStateLabel}
            project={project}
            handleChange={handleChange}
            handleOpenCompanyContactModal={handleOpenCompanyContactModal}
          />

          <InputTransporterCompany
            isAuthUser={isAuthUser}
            isRequired={isRequired}
            canProjectViewAllManifests={canProjectViewAllManifests}
            wasSubmitted={wasSubmitted}
            getStateLabel={getStateLabel}
            project={project}
            handleChange={handleChange}
            handleChangeCanViewCheckbox={handleChangeCanViewCheckbox}
            isLoadingCompanies={isLoadingCompanies}
            cTransporters={cTransporters}
            brokers={brokers}
            handleOpenCompanyContactModal={handleOpenCompanyContactModal}
          />

          <PermissionsWrapper canAccess={[UserTypes.admin.super]}>
            <Grid item xs={12}>
              <CrudListIdName
                title="Reports"
                list={project.reports || []}
                onListUpdate={list =>
                  handleChange({
                    target: {
                      name: 'reports',
                      value: list,
                    },
                  })
                }
              />
            </Grid>
          </PermissionsWrapper>
          <Grid item sm={6} xs={12}>
            {scaleCompanies && (
              <Autocomplete
                disabled={isAuthUser.readOnly}
                options={scaleCompanies || []}
                value={project.scaleCompany || null}
                isOptionEqualToValue={(option, value) => (value ? option.id === value.id : false)}
                getOptionLabel={option => option.name}
                onChange={onScaleCompanyChange}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    required
                    label="Scale Company"
                    placeholder="Select scale company"
                    error={hasErrorRequired(project.scaleCompany, wasSubmitted)}
                    helperText={
                      hasErrorRequired(project.scaleCompany, wasSubmitted) ? isRequired : ''
                    }
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {isLoadingCompanies && <CircularProgress color="inherit" size={20} />}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                renderOption={(props: HTMLAttributes<HTMLLIElement>, option: ICompany) => (
                  <li {...props} key={option.id}>
                    <Grid container alignItems="center" justifyContent="flex-start" wrap="nowrap">
                      <Grid item xs={6}>
                        <Typography noWrap>{option.name}</Typography>
                      </Grid>
                    </Grid>
                  </li>
                )}
              />
            )}
          </Grid>
          <Grid item sm={6} xs={12}>
            {scaleCompanies && (
              <Autocomplete
                disabled={isAuthUser.readOnly || !project.scaleCompany?.regions}
                options={project.scaleCompany?.regions || []}
                value={
                  project.scaleCompany?.regions?.find(
                    (region: any) => region.id === project.regionId
                  ) || null
                }
                isOptionEqualToValue={(option, value) => (value ? option.id === value.id : false)}
                getOptionLabel={option => (option.name ? option.name : option)}
                onChange={(event, value) =>
                  handleChange({
                    target: {
                      name: 'regionId',
                      value: value ? value.id : null,
                    },
                  })
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    required
                    label="Region"
                    placeholder="Select project region"
                    error={hasErrorRequired(project.regionId, wasSubmitted)}
                    helperText={hasErrorRequired(project.regionId, wasSubmitted) ? isRequired : ''}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: <>{params.InputProps.endAdornment}</>,
                    }}
                  />
                )}
              />
            )}
          </Grid>
          {project.scaleCompany && (
            <>
              <Grid item sm={6} xs={12}>
                <Autocomplete
                  fullWidth
                  disabled={isAuthUser.readOnly}
                  options={manifestTemplates || []}
                  getOptionLabel={option => option.name}
                  value={
                    (manifestTemplates || []).find(
                      (template: any) => template.id === project.manifestTemplateId
                    ) || null
                  }
                  onChange={(event, value) =>
                    handleChange({
                      target: {
                        name: 'manifestTemplateId',
                        value: (value && value.id) || null,
                      },
                    })
                  }
                  renderInput={params => (
                    <TextField
                      {...params}
                      required
                      label="Manifest template"
                      placeholder="Select manifest template"
                      error={hasErrorRequired(project.manifestTemplateId, wasSubmitted)}
                      helperText={
                        hasErrorRequired(project.manifestTemplateId, wasSubmitted) ? isRequired : ''
                      }
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: <>{params.InputProps.endAdornment}</>,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Autocomplete
                  fullWidth
                  disabled={isAuthUser.readOnly}
                  options={receiptTemplates || []}
                  getOptionLabel={option => option.name}
                  value={
                    (receiptTemplates || []).find(
                      (template: any) => template.id === project.receiptTemplateId
                    ) || null
                  }
                  onChange={(event, value) =>
                    handleChange({
                      target: {
                        name: 'receiptTemplateId',
                        value: (value && value.id) || null,
                      },
                    })
                  }
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Receipt template"
                      placeholder="Select receipt template"
                      error={hasErrorRequired(project.receiptTemplateId, wasSubmitted)}
                      helperText={
                        hasErrorRequired(project.receiptTemplateId, wasSubmitted) ? isRequired : ''
                      }
                      required
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: <>{params.InputProps.endAdornment}</>,
                      }}
                    />
                  )}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Autocomplete
              disabled={isAuthUser.readOnly || !project.scaleCompany}
              multiple
              options={scales}
              value={project.scales || []}
              isOptionEqualToValue={(option, value) => (value ? option.id === value.id : false)}
              getOptionLabel={option => option.name}
              getOptionDisabled={scale => !scale.lanes || !scale.lanes.length}
              onChange={(event, value) =>
                handleChange({
                  target: {
                    name: 'scales',
                    value: value.map(x => ({
                      id: x.id,
                      name: x.name || null,
                      address: x.address || null,
                      state: x.state || null,
                      lanes: x.lanes || [],
                    })),
                  },
                })
              }
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                  required
                  label="Scales"
                  placeholder="Select project scales"
                  error={hasErrorRequired(project.scales, wasSubmitted)}
                  helperText={hasErrorRequired(project.scales, wasSubmitted) ? isRequired : ''}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {isLoadingScales && <CircularProgress color="inherit" size={20} />}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              disabled={isAuthUser.readOnly}
              onChange={handleChange}
              required
              fullWidth
              label="Generator's mailing address"
              name="generatorMailingAddress"
              value={project.generatorMailingAddress || ''}
              variant="outlined"
              error={hasErrorRequired(project.generatorMailingAddress, wasSubmitted)}
              helperText={
                hasErrorRequired(project.generatorMailingAddress, wasSubmitted) ? isRequired : ''
              }
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <InputGeocodingAddress
              disabled={isAuthUser.readOnly}
              onChange={onSiteAdressChange}
              onCoordinatesChange={onCoordinatesChange}
              label={`Generator's site address (${
                project.coords
                  ? `lat: ${(parseFloat(project.coords.gpsLat) || 0).toFixed(3)}; lon: ${(
                      parseFloat(project.coords.gpsLng) || 0
                    ).toFixed(3)}`
                  : 'no coords'
              })`}
              value={project.generatorSiteAddress || ''}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <TextField
              disabled={isAuthUser.readOnly}
              onChange={handleChange}
              required
              fullWidth
              label="Generator's phone"
              name="generatorPhone"
              value={project.generatorPhone || ''}
              variant="outlined"
              error={hasErrorRequired(project.generatorPhone, wasSubmitted)}
              helperText={hasErrorRequired(project.generatorPhone, wasSubmitted) ? isRequired : ''}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              disabled={isAuthUser.readOnly}
              onChange={handleChange}
              required
              fullWidth
              label="Additional descriptions for materials"
              name="additionalDescriptions"
              value={project.additionalDescriptions || ''}
              variant="outlined"
              error={hasErrorRequired(project.additionalDescriptions, wasSubmitted)}
              helperText={
                hasErrorRequired(project.additionalDescriptions, wasSubmitted) ? isRequired : ''
              }
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              disabled={isAuthUser.readOnly}
              onChange={handleChange}
              required
              fullWidth
              label="Description of waste materials"
              name="descriptionOfWasteMaterials"
              value={project.descriptionOfWasteMaterials || ''}
              variant="outlined"
              error={hasErrorRequired(project.descriptionOfWasteMaterials, wasSubmitted)}
              helperText={
                hasErrorRequired(project.descriptionOfWasteMaterials, wasSubmitted)
                  ? isRequired
                  : ''
              }
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              disabled={isAuthUser.readOnly}
              onChange={handleChange}
              required
              fullWidth
              label="Bill to"
              name="billTo"
              value={project.billTo || ''}
              variant="outlined"
              error={hasErrorRequired(project.billTo, wasSubmitted)}
              helperText={hasErrorRequired(project.billTo, wasSubmitted) ? isRequired : ''}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              disabled={isAuthUser.readOnly}
              onChange={handleChange}
              fullWidth
              label="Emergency contact / Phone number"
              name="emergencyContactAndPhone"
              value={project.emergencyContactAndPhone || ''}
              variant="outlined"
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              disabled={isAuthUser.readOnly}
              onChange={handleChange}
              fullWidth
              type="number"
              label="Minimum signed manifests threshold"
              name="signedManifestThresholdMinimum"
              value={project.signedManifestThresholdMinimum || ''}
              variant="outlined"
            />
          </Grid>
          <PermissionsWrapper canAccess={[UserTypes.admin.super]}>
            <Grid item sm={6} xs={12} style={{ display: 'flex' }}>
              <Box
                display="flex"
                width="100%"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="body1" style={{ marginLeft: 16 }}>
                  Expiry Date:{' '}
                </Typography>
                <DatePicker
                  size="lg"
                  value={
                    typeof project?.expiryDate !== 'undefined' && project?.expiryDate
                      ? project.expiryDate.toDate()
                      : undefined
                  }
                  onChange={handleChangeExpiryDate}
                  style={{
                    minWidth: 280,
                    marginTop: 'auto',
                    marginBottom: 'auto',
                  }}
                />
              </Box>
            </Grid>
          </PermissionsWrapper>
          {(isAuthUser.superAdmin || isAuthUser.customerAdmin) &&
            !!project.scaleCompany?.externalApiPropertyNamesForMappingsInProjects?.length && (
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h5">External API properties</Typography>
                  </Grid>
                  {project.scaleCompany.externalApiPropertyNamesForMappingsInProjects.map(
                    (propertyMapping: any) => (
                      <Grid item xs={12} key={propertyMapping.name}>
                        <TextField
                          disabled={isAuthUser.readOnly}
                          onChange={handleChangeInExternalApiMappings}
                          fullWidth
                          label={propertyMapping.name}
                          name={propertyMapping.name}
                          value={
                            project.externalApiMappings
                              ? project.externalApiMappings[propertyMapping.name] || ''
                              : ''
                          }
                          variant="outlined"
                        />
                      </Grid>
                    )
                  )}
                </Grid>
              </Grid>
            )}
          <PermissionsWrapper canAccess={[UserTypes.admin.super]}>
            <Grid item xs={12}>
              <CustomInputsDuplicate
                setCustomInputsDefinitions={handleCustomInputsDefinitionsChange}
                hasManifests={hasManifests}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomInputsDefinitionsCrud
                customInputsDefinitions={project.customInputsDefinitions || []}
                setCustomInputsDefinitions={handleCustomInputsDefinitionsChange}
                wasSubmitted={wasSubmitted}
                userCanEdit={!isAuthUser.generator}
                generatorCompanyId={project?.generatorCompany?.id || ''}
                hasManifests={hasManifests}
              />
            </Grid>
          </PermissionsWrapper>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default ProjectDetailsEdit;
